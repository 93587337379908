import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/donald-alfred-stephenson-jr.jpg";
  const fullName = "Donald Alfred Stephenson Jr.";
  const zoomUrl = "";
  const zoomPhoneNumber = "";
  const zoomMeetingId = "";
  const birthDate = "July 12, 1978";
  const deathDate = "October 23, 2020";
  const memorialDate = "Saturday November 21st, 2020";
  const memorialTime = "11am CST";
  const inviteText = "";
  const obituary = ``;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedUrl = "https://www.youtube.com/embed/NQc5dnKu1GM";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      optInviteChild=""
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      recordingEmbedUrl={recordingEmbedUrl}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
    ></StoryPage>
  );
};

export default ThisPage;
